import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BaseEventsService {
  private subject = new BehaviorSubject<EventsServiceData>(new EventsServiceData());
  private filterConditionsSubject = new Subject<SearchItemModel[]>();
  private filterCriterions!: Criterion[];
  public dataWasLoaded!: boolean;

  private filters!: SearchItemModel[];
  private order!: Order[];
  private pagination!: Pagination;
  public eventServiceName: string;

  constructor(@Inject(String) eventServiceName: string) {
    this.eventServiceName = eventServiceName;
  }

  public getSubject(): Observable<EventsServiceData> {
    return this.subject.asObservable();
  }

  public onSelected(id: string[]): void {
    this.subject.next({ id, action: EventsServiceAction.Select });
  }

  public onReviewLink(id: string[]): void {
    this.subject.next({ id, action: EventsServiceAction.ReviewLink });
  }

  public onQueryLink(id: string[]): void {
    this.subject.next({ id, action: EventsServiceAction.QueryLink });
  }

  public onAddClausesLink(id: string[]): void {
    this.subject.next({ id, action: EventsServiceAction.AddClausesLink });
  }

  public onEditProperties(id: string[]): void {
    this.subject.next({ id, action: EventsServiceAction.EditProperties });
  }

  public onView(id: number): void {
    this.subject.next({ id, action: EventsServiceAction.View });
  }

  public onEdit(id: number): void {
    this.subject.next({ id, action: EventsServiceAction.Edit });
  }

  public onSendReport(id: number): void {
    this.subject.next({ id, action: EventsServiceAction.SendReport });
  }

  public onEditInDesktop(id: number): void {
    this.subject.next({ id, action: EventsServiceAction.EditInDesktop });
  }

  public onDelete(id: number): void {
    this.subject.next({ id, action: EventsServiceAction.Delete });
  }

  public onSearch(id: number): void {
    this.subject.next({ id, action: EventsServiceAction.Search });
  }

  public onCancel(id: number): void {
    this.subject.next({ id, action: EventsServiceAction.Cancel });
  }

  public onApply(id: number): void {
    this.subject.next({ id, action: EventsServiceAction.Apply });
  }

  public onCase(id: number): void {
    this.subject.next({ id, action: EventsServiceAction.Case });
  }

  public onDetails(id: number): void {
    this.subject.next({ id, action: EventsServiceAction.Details });
  }

  public onDownload(id: number): void {
    this.subject.next({ id, action: EventsServiceAction.Download });
  }

  public onPreview(id: number): void {
    this.subject.next({ id, action: EventsServiceAction.Previw });
  }

  public getFilterConditionsSubject(): Observable<SearchItemModel[]> {
    return this.filterConditionsSubject.asObservable();
  }

  public saveFilters(filters: SearchItemModel[]): void {
    // localStorage.setItem(this.filterStorageName, JSON.stringify(filters));
    this.filters = filters;
  }

  public getFilters(): SearchItemModel[] {
    // if (!this.filters) {
    //   const filtersData = localStorage.getItem(this.filterStorageName);

    //   if (filtersData) {
    //     this.filters = JSON.parse(filtersData);
    //   }
    // }

    return this.filters;
  }

  public onFilter(filters: SearchItemModel[]): void {
    this.saveFilters(filters);

    //this.waitFor(this.dataWasLoaded, () => {
    this.filterConditionsSubject.next(filters);
    //});
  }

  public waitFor(condition: boolean, callback: any): any {
    if (!condition) {
      // send that
      window.setTimeout(this.waitFor.bind(null, condition, callback), 50);
    } else {
      callback();
    }
  }

  public setFilterCriterions(criterions: Criterion[]): void {
    // localStorage.setItem(this.filterCriterionsStorageName, JSON.stringify(criterions));
    this.filterCriterions = criterions;
  }

  public getFilterCriterions(): Criterion[] {
    // if (!this.filterCriterions) {
    //   this.filterCriterions = JSON.parse(
    //     localStorage.getItem(this.filterCriterionsStorageName) || '',
    //   );
    // }

    return this.filterCriterions;
  }

  public saveOrder(order: Order[]): void {
    this.order = order;
  }

  public getOrder(): Order[] {
    return this.order;
  }

  public savePagination(pagination: Pagination): void {
    this.pagination = pagination;
  }

  public getPagination(): Pagination {
    return this.pagination;
  }

  public resetPagination(): void {
    this.pagination = {
      start: 0,
      length: this.pagination?.length ? this.pagination.length : 10,
    } as Pagination;
  }

  get filterStorageName(): string {
    return this.eventServiceName + '_filters';
  }

  get filterCriterionsStorageName(): string {
    return this.eventServiceName + '_filterCriterions';
  }
}

export class EventsServiceData {
  id: any;
  action!: EventsServiceAction;
}

export class Pagination {
  start!: number;
  length!: number;
}

export enum EventsServiceAction {
  View = 1,
  Edit = 2,
  Delete = 3,
  Search = 4,
  Cancel = 5,
  Apply = 6,
  Case = 7,
  Details = 8,
  Download = 9,
  Previw = 12,
  Select = 13,
  ReviewLink = 14,
  QueryLink = 15,
  AddClausesLink = 16,
  EditProperties = 17,
  EditInDesktop = 18,
  SendReport = 19,
}

export class SearchItemModel {
  criterion!: number; // Criterion id
  operator!: number; // SearchOperator id
  searchValue!: string; // value to be searched
  type!: number; // SearchCriterionsDataType
  controlName!: string;
  // entity!: string;
}

export class Criterion {
  id?: number;
  title?: string;
  data?: string[];
  type?: number; // SearchCriterionsDataType
  // entity?: string;
}

export class Order {
  column?: number;
  dir?: PaginationOrder;
}

export enum PaginationOrder {
  Asc = 'Asc',
  Desc = 'Desc',
}
