import { SearchItemModel } from '../services/base-event-service';
import { SearchCriterionsDataType } from '../services/client-side-search.service';

export function getFiltersFormModel(filters: SearchItemModel[]): any {
  let model: FormControlDataModel = {};

  filters.forEach((filter: SearchItemModel) => {
    if (filter.type === SearchCriterionsDataType.Integer) {
      model[filter.controlName] =
        filter.searchValue == '' ? [] : filter.searchValue.split(',').map((value) => +value);
    }
    if (filter.type === SearchCriterionsDataType.ArrayNumber) {
      model[filter.controlName] =
        filter.searchValue == '' ? [] : filter.searchValue.split(',').map((value) => +value);
    }
    if (filter.type === SearchCriterionsDataType.String) {
      model[filter.controlName] = filter.searchValue == '' ? [] : filter.searchValue;
    }
  });

  return model;
}

type FormControlDataModel = Record<string, any>;
